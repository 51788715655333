import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import FilterListRounded from '@material-ui/icons/FilterListRounded';
import AddIcon from '@material-ui/icons/InfoOutlined';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { SvgLoader, SvgProxy } from 'react-svgmt';
import * as types from '../constants/ActionTypes';
import getSubdomain from '../utilities/getSubdomain';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,

    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex'
  },

  grow: {
    flexGrow: 1
  },

  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },

  searchWhite: {
    flexGrow: 2,
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#f7f7f7',
    '&:hover': {
      backgroundColor: '#ebebeb'
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },

  search: {
    flexGrow: 2,
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10
  },
  inputRoot: {
    color: 'inherit',
    width: '100%'
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 400
    }
  },

  toolbar: theme.mixins.toolbar
}));

function NavigationBar({
  resellerTheme,

  handleDrawerToggle
}) {
  const classes = useStyles();
  const [infoOpen, setInfoOpen] = useState(false);

  const dispatch = useDispatch();
  const selectedQuery = useSelector(state => state.selectedQuery);

  const { hasResellerTheme, logoUrl, secondaryBrandColor } = resellerTheme;

  let mail = getSubdomain() ? getSubdomain() : 'info';

  function handleQueryChange(event) {
    dispatch({
      type: types.SELECT_QUERY,
      query: event.target.value
    });
  }

  function handleClickOpen() {
    ReactGA.modalview('/contact-us');
    setInfoOpen(true);
  }

  function handleInfoClose() {
    setInfoOpen(false);
  }

  return (
    <>
      <Dialog
        open={infoOpen}
        onClose={() => {
          handleInfoClose();
        }}
      >
        <DialogContent>
          <Typography variant="h5" gutterBottom align="center">
            Welkom bij de B2B Lead Generator
          </Typography>
          <Typography variant="body1" gutterBottom align="center">
            Vragen? Feedback? Neem contact met ons op.
          </Typography>

          <div
            className="layout flex vertical center-center"
            style={{ height: '300px' }}
          >
            <SvgLoader
              style={{ height: '200px', width: '100%' }}
              path={'/email.svg'}
            >
              <SvgProxy selector="#secondary" stroke={secondaryBrandColor} />

              <SvgProxy selector="#primary" stroke={secondaryBrandColor} />
            </SvgLoader>

            <Button
              href={`mailto:${mail}@lead-generator.app`}
              style={{ marginTop: '20px' }}
              size="small"
              variant="outlined"
              color="primary"
            >
              Mail ons
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleInfoClose();
            }}
            color="primary"
          >
            Sluit venster
          </Button>
        </DialogActions>
      </Dialog>
      <Toolbar>
        {/* <Hidden smUp implementation="css"> */}
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={handleDrawerToggle}
        >
          <FilterListRounded />
        </IconButton>
        {/* </Hidden> */}

        {/* <Hidden xsDown implementation="css">
          <div className="layout flex horizontal">
            {hasResellerTheme ? (
              <img alt="" style={{ height: '30px' }} src={logoUrl} />
            ) : (
              <Typography variant="h6" color="inherit">
                B2B Lead Generator
              </Typography>
            )}
          </div>
        </Hidden> */}
        <div className={classes.grow} />
        <div
          className={
            hasResellerTheme === true ? classes.searchWhite : classes.search
          }
          style={hasResellerTheme === true ? { backgroundColor: '#fff' } : {}}
        >
          <div className={classes.searchIcon}>
            <SearchIcon
              style={hasResellerTheme ? { color: 'rgba(0,0,0,0.38)' } : {}}
            />
          </div>

          <InputBase
            placeholder="Zoeken naar..."
            value={selectedQuery}
            onChange={event => {
              handleQueryChange(event);
            }}
            style={hasResellerTheme ? { background: '#f7f7f7' } : {}}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
          />
        </div>
        <div className={classes.grow} />
        <div
          className="layout flex horizontal end end-justified"
          style={{ flexGrow: 1 }}
        >
          <IconButton
            onClick={() => {
              handleClickOpen();
            }}
            aria-label="Info"
          >
            <AddIcon color="secondary" />
          </IconButton>
        </div>
      </Toolbar>
    </>
  );
}

export default NavigationBar;
