import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import BusinessOutlined from '@material-ui/icons/BusinessOutlined';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import LanguageOutlined from '@material-ui/icons/LanguageOutlined';
import LooksOneOutlined from '@material-ui/icons/LooksOneOutlined';
import MailOutlined from '@material-ui/icons/MailOutlined';
import PeopleOutlined from '@material-ui/icons/PeopleOutlined';
import PhoneOutlined from '@material-ui/icons/PhoneOutlined';
import PlaceOutlined from '@material-ui/icons/PlaceOutlined';
import { makeStyles } from '@material-ui/styles';
import 'rc-tooltip/assets/bootstrap.css';
import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import WebsiteApps from '../components/WebsiteApps';
import getDomain from '../utilities/getDomain';
import getSubdomain from '../utilities/getSubdomain';

const drawerWidth = 360;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex'
  },

  speedDial: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  },
  socialIcons: {
    marginRight: '10px'
  },
  socialButton: {
    marginRight: '10px'
  },

  expansionPanelSummary: {
    paddingLeft: '0px',
    paddingRight: '0px'
  },

  companyLink: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    MozOsxFontSmoothing: 'grayscale',
    WebkitFontSmoothing: 'antialiased !important',
    MozFontSmoothing: 'antialiased !important',
    textRendering: 'optimizelegibility !important',
    letterSpacing: '.03em'
  },

  table: {
    minWidth: 1020
  },
  tableWrapper: {
    overflowX: 'auto'
  },

  avatar: {
    background: theme.palette.primary.main
  },

  appBarWhite: {
    zIndex: theme.zIndex.drawer + 1,
    background: '#fff'
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    overflow: 'auto',
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },

  toolbar: theme.mixins.toolbar,

  margin: {
    margin: theme.spacing(1)
  },

  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  },
  bootstrapFormLabel: {
    fontSize: 18
  }
}));

function CompanyInfoDialog({
  companyDataForDialog,
  openDialog,
  handleClose,
  handleAboutDataClickOpen
}) {
  const classes = useStyles();

  function addDefaultSrc(ev) {
    ev.target.src = './icons/Gravatar.png';
  }

  return (
    <Dialog open={openDialog} onClose={handleClose} hideBackdrop>
      <DialogContent>
        <div className="layout flex vertical">
          <div className="layout flex horizontal">
            <div className="layout flex flex-3">
              <CardHeader
                avatar={
                  <Avatar
                    onError={addDefaultSrc}
                    src={
                      companyDataForDialog['url']
                        ? `https://logo.clearbit.com/${getDomain(
                            companyDataForDialog['url'][0]
                          )}`
                        : ''
                    }
                    className={classes.avatar}
                  />
                }
                title={companyDataForDialog.name}
                subheader={companyDataForDialog['categoryshort']}
              />
            </div>
          </div>

          <div style={{ padding: '0 24px' }} className="layout flex horizontal">
            {companyDataForDialog.site_facebook_link ? (
              <Button
                href={
                  Array.isArray(companyDataForDialog.site_facebook_link)
                    ? companyDataForDialog.site_facebook_link[0]
                    : companyDataForDialog.site_facebook_link
                }
                variant="outlined"
                color="primary"
                size="small"
                className={classes.socialButton}
              >
                <FaFacebook className={classes.socialIcons} /> {'  '}
                Facebook
              </Button>
            ) : null}

            {companyDataForDialog.site_twitter_link ? (
              <Button
                href={
                  Array.isArray(companyDataForDialog.site_twitter_link)
                    ? companyDataForDialog.site_twitter_link[0]
                    : companyDataForDialog.site_twitter_link
                }
                variant="outlined"
                color="primary"
                size="small"
                className={classes.socialButton}
              >
                <FaTwitter className={classes.socialIcons} /> {'  '}
                Twitter
              </Button>
            ) : null}

            {companyDataForDialog.site_linkedin_link ? (
              <Button
                href={
                  Array.isArray(companyDataForDialog.site_linkedin_link)
                    ? companyDataForDialog.site_linkedin_link[0]
                    : companyDataForDialog.site_linkedin_link
                }
                variant="outlined"
                color="primary"
                size="small"
                className={classes.socialButton}
              >
                <FaLinkedin className={classes.socialIcons} /> {'  '}
                Linkedin
              </Button>
            ) : null}

            {companyDataForDialog.site_instagram_link ? (
              <Button
                href={
                  Array.isArray(companyDataForDialog.site_instagram_link)
                    ? companyDataForDialog.site_instagram_link[0]
                    : companyDataForDialog.site_instagram_link
                }
                variant="outlined"
                color="primary"
                size="small"
                className={classes.socialButton}
              >
                <FaInstagram className={classes.socialIcons} /> {'  '}
                Instagram
              </Button>
            ) : null}
          </div>

          <List component="div">
            <ListItem>
              <ListItemIcon>
                <LooksOneOutlined />
              </ListItemIcon>
              <ListItemText
                primary={
                  companyDataForDialog.coc
                    ? 'KvK-nummer: ' + companyDataForDialog.coc
                    : '-'
                }
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <BusinessOutlined />
              </ListItemIcon>
              <ListItemText
                primary={
                  companyDataForDialog.orgtype
                    ? `${companyDataForDialog.orgtype}${
                        companyDataForDialog.type &&
                        companyDataForDialog.type === 'Nevenvestiging'
                          ? ` (${companyDataForDialog.type})`
                          : ''
                      }`
                    : '-'
                }
              />
            </ListItem>

            {companyDataForDialog.employees &&
            companyDataForDialog.employees !== 1 ? (
              <ListItem>
                <ListItemIcon>
                  <PeopleOutlined />
                </ListItemIcon>
                <ListItemText
                  primary={
                    companyDataForDialog.employees
                      ? `${companyDataForDialog.employees} medewerkers`
                      : '-'
                  }
                />
              </ListItem>
            ) : null}

            <ListItem>
              <ListItemIcon>
                <PlaceOutlined />
              </ListItemIcon>
              <ListItemText
                className={
                  companyDataForDialog.address &&
                  companyDataForDialog.address === 'Voorbeeldstraat 1'
                    ? 'blurry-text'
                    : ''
                }
                primary={
                  companyDataForDialog.address
                    ? `${companyDataForDialog.address}, ${companyDataForDialog.postcode} ${companyDataForDialog.city}`
                    : '-'
                }
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <LanguageOutlined />
              </ListItemIcon>
              <ListItemText
                primary={
                  companyDataForDialog.url
                    ? getDomain(companyDataForDialog['url'][0])
                    : '-'
                }
              />

              {companyDataForDialog['url'] ? (
                <ListItemSecondaryAction>
                  <Button
                    variant="outlined"
                    href={`https://${
                      getSubdomain() ? `${getSubdomain()}.` : ''
                    }web-analyzer.app/scan/${getDomain(
                      companyDataForDialog['url'][0]
                    )}`}
                    color="secondary"
                    aria-label="Web Analyzer"
                  >
                    Bekijk in Web Analyzer
                  </Button>
                </ListItemSecondaryAction>
              ) : null}
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <MailOutlined />
              </ListItemIcon>
              <ListItemText
                className={
                  companyDataForDialog.email &&
                  companyDataForDialog.email[0] === 'info@voorbeeld-bedrijf.nl'
                    ? 'blurry-text'
                    : ''
                }
                primary={
                  companyDataForDialog.email
                    ? companyDataForDialog.email[0]
                    : '-'
                }
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <PhoneOutlined />
              </ListItemIcon>
              <ListItemText
                className={
                  companyDataForDialog.phone &&
                  companyDataForDialog.phone[0] === '0123-456789'
                    ? 'blurry-text'
                    : ''
                }
                primary={
                  companyDataForDialog.phone
                    ? companyDataForDialog.phone[0]
                    : '-'
                }
              />
            </ListItem>
          </List>

          {companyDataForDialog.orgtype === 'Eenmanszaak' ? (
            <div
              style={{
                marginTop: '10px',
                marginBottom: '10px'
              }}
              className="layout flex horizontal center-center"
            >
              <Chip
                color="primary"
                variant="outlined"
                onClick={handleAboutDataClickOpen}
                icon={<InfoOutlined />}
                label="Waarom zijn niet alle gegevens beschikbaar?"
              />
            </div>
          ) : null}
          {companyDataForDialog.site_tech_apps ? (
            <ExpansionPanel style={{ marginTop: '20px' }}>
              <ExpansionPanelSummary
                className={classes.expansionPanelSummary}
                expandIcon={<ExpandMoreIcon />}
              >
                <CardHeader
                  avatar={
                    <Avatar className={classes.avatar}>
                      <ErrorOutline />
                    </Avatar>
                  }
                  title="Website Apps"
                  subheader="Van welke technologieën maakt de website gebruik?"
                />
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className="layout flex vertical">
                  <WebsiteApps apps={companyDataForDialog.site_tech_apps} />
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ) : null}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Maak lead in AFAS
        </Button>
        <Button onClick={handleClose} color="secondary">
          Voeg toe aan AFAS
        </Button>
        <Button onClick={handleClose} color="primary">
          Sluit venster
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CompanyInfoDialog;
