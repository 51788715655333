import ListSubheader from '@material-ui/core/ListSubheader';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GeneralFilterSetting from '../components/GeneralFilterSetting';
import GeneralFilterSettingWrapper from '../components/GeneralFilterSettingWrapper';
import * as types from '../constants/ActionTypes';
import * as sTypes from '../constants/SettingTypes';

function GeneralFilterSettingsContainer() {
  const dispatch = useDispatch();
  const settings = useSelector(state => state.settings);

  let {
    phone_exists,
    email_exists,
    winkel_exists,
    werkgever_exists,
    site_webshop_exists,
    afas_exists,
    company_on_site
  } = settings;

  function handleSettingsChange(event, name) {
    let value = event.target.checked;
    let newSettings = {
      ...settings,
      [name]: value
    };
    dispatch({
      type: types.UPDATE_SETTINGS,
      settings: newSettings
    });
  }

  return (
    <>
      <ListSubheader component="div">Algemene filters</ListSubheader>
      <GeneralFilterSettingWrapper>
        {/* <GeneralFilterSetting
          value={phone_exists}
          valueLabel={sTypes.PHONE_EXISTS}
          label={'Telefoonnummer gevonden'}
          handleSettingsChange={event => {
            handleSettingsChange(event, sTypes.PHONE_EXISTS);
          }}
        />
        <GeneralFilterSetting
          value={email_exists}
          valueLabel={sTypes.EMAIL_EXISTS}
          label={'E-mailadres gevonden'}
          handleSettingsChange={event => {
            handleSettingsChange(event, sTypes.EMAIL_EXISTS);
          }}
        /> */}
        <GeneralFilterSetting
          value={winkel_exists}
          valueLabel={sTypes.WINKEL_EXISTS}
          label={'Winkel gevonden'}
          handleSettingsChange={event => {
            handleSettingsChange(event, sTypes.WINKEL_EXISTS);
          }}
        />
        <GeneralFilterSetting
          value={werkgever_exists}
          valueLabel={sTypes.WERKGEVER_EXISTS}
          label={'Vacatures gevonden'}
          handleSettingsChange={event => {
            handleSettingsChange(event, sTypes.WERKGEVER_EXISTS);
          }}
        />
        <GeneralFilterSetting
          value={site_webshop_exists}
          valueLabel={sTypes.SITE_WEBSHOP_EXISTS}
          label={'Webshop gevonden'}
          handleSettingsChange={event => {
            handleSettingsChange(event, sTypes.SITE_WEBSHOP_EXISTS);
          }}
        />
        <GeneralFilterSetting
          value={phone_exists}
          valueLabel={sTypes.AFAS_EXISTS}
          label={'Gevonden in AFAS'}
          handleSettingsChange={event => {
            handleSettingsChange(event, sTypes.AFAS_EXISTS);
          }}
        />
        <GeneralFilterSetting
          value={email_exists}
          valueLabel={sTypes.COMPANY_ON_SITE}
          label={'Bedrijven op website'}
          handleSettingsChange={event => {
            handleSettingsChange(event, sTypes.COMPANY_ON_SITE);
          }}
        />
      </GeneralFilterSettingWrapper>
    </>
  );
}

export default GeneralFilterSettingsContainer;
