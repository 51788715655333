import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/styles';
import 'rc-tooltip/assets/bootstrap.css';
import React from 'react';
import translate from '../data/translate.json';
import Button from '@material-ui/core/Button';
import { Dialog, DialogActions, DialogTitle } from '@material-ui/core';

const drawerWidth = 360;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex'
  },

  speedDial: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  },
  socialIcons: {
    marginRight: '10px'
  },
  socialButton: {
    marginRight: '10px'
  },

  expansionPanelSummary: {
    paddingLeft: '0px',
    paddingRight: '0px'
  },

  companyLink: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    MozOsxFontSmoothing: 'grayscale',
    WebkitFontSmoothing: 'antialiased !important',
    MozFontSmoothing: 'antialiased !important',
    textRendering: 'optimizelegibility !important',
    letterSpacing: '.03em'
  },

  table: {
    minWidth: 1020
  },
  tableWrapper: {
    overflowX: 'auto'
  },

  avatar: {
    background: theme.palette.primary.main
  },

  appBarWhite: {
    zIndex: theme.zIndex.drawer + 1,
    background: '#fff'
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    overflow: 'auto',
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },

  toolbar: theme.mixins.toolbar,

  margin: {
    margin: theme.spacing(1)
  },

  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  },
  bootstrapFormLabel: {
    fontSize: 18
  }
}));

function CompanyTable({ companies, handleClickOpen }) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [openGladior, setOpenGladior] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseGladior = () => {
    setOpenGladior(false);
  };

  const leadscoresDemo = [5, 10, 20, 30, 40, 50, 60, 70, 80, 90];

  return (
    <>
      <Dialog
        maxWidth="lg"
        open={openGladior}
        onClose={handleCloseGladior}
        hideBackdrop
      >
        <iframe
          src="https://bedrijven.datalaag.nl/company-info?colors=23326b-ed1e36&companyId=081379910000&period=20210621-20230204&accountToken=LrWOTT5aUX2M8xR3fPFFC6YbrNVvND4d5Gf3JC0U&analyticsToken=F4F584E3-CA45-411D-8547-DDD130B2E12C"
          height="700px"
          width="800px"
          title="company"
        ></iframe>
        <DialogActions>
          <Button onClick={handleCloseGladior}>Sluit venster</Button>
        </DialogActions>
      </Dialog>
      <Dialog maxWidth="lg" open={open} onClose={handleClose} hideBackdrop>
        <iframe
          src="https://bedrijven.datalaag.nl/company-info?colors=23326b-ed1e36&companyId=404785910000&period=20210621-20230204&accountToken=LrWOTT5aUX2M8xR3fPFFC6YbrNVvND4d5Gf3JC0U&analyticsToken=F4F584E3-CA45-411D-8547-DDD130B2E12C"
          height="700px"
          width="800px"
          title="company"
        ></iframe>
        <DialogActions>
          <Button onClick={handleClose}>Sluit venster</Button>
        </DialogActions>
      </Dialog>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell style={{ textTransform: 'capitalize' }}>
              {translate['name'] ? translate['name'].name : ''}
            </TableCell>
            <TableCell style={{ textTransform: 'capitalize' }}>
              {translate['orgtype'] ? translate['orgtype'].name : ''}
            </TableCell>
            {/* <TableCell style={{ textTransform: 'capitalize' }}>
            {translate['url'] ? translate['url'].name : ''}{' '}
          </TableCell> */}
            <TableCell style={{ textTransform: 'capitalize' }}>
              {translate['categoryshort']
                ? translate['categoryshort'].name
                : ''}
            </TableCell>
            <TableCell style={{ textTransform: 'capitalize' }}>
              {translate['city'] ? translate['city'].name : ''}
            </TableCell>

            <TableCell style={{ textTransform: 'capitalize' }}>
              {translate['provincie'] ? translate['provincie'].name : ''}
            </TableCell>
            <TableCell style={{ textTransform: 'capitalize' }}>
              {/* {translate['lead'] ? translate['lead'].name : ''} */}
              AFAS
            </TableCell>
            <TableCell style={{ textTransform: 'capitalize' }}>
              {/* {translate['lead'] ? translate['lead'].name : ''} */}
              lead
            </TableCell>
            <TableCell style={{ textTransform: 'capitalize' }}>
              {/* {translate['lead'] ? translate['lead'].name : ''} */}
              leadscore
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              onClick={() => {
                setOpen(true);
              }}
              className={classes.companyLink}
            >
              Koninklijke Nederlandse Voetbalbond (demo)
            </TableCell>
            <TableCell>-</TableCell>
            {/* <TableCell>{company['url'] ? company['url'][0] : '-'} </TableCell> */}
            <TableCell>Overkoepelende organen e.d. (sport)</TableCell>
            <TableCell>Zeist</TableCell>
            <TableCell>Utrecht</TableCell>
            <TableCell>
              <Button onClick="" color="secondary">
                Voeg toe
              </Button>
            </TableCell>
            <TableCell>
              <Button onClick="" color="secondary">
                Maak lead
              </Button>
            </TableCell>
            <TableCell>100%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              onClick={() => {
                setOpenGladior(true);
              }}
              className={classes.companyLink}
            >
              Gladior B.V. (demo)
            </TableCell>
            <TableCell>BV</TableCell>
            {/* <TableCell>{company['url'] ? company['url'][0] : '-'} </TableCell> */}
            <TableCell> Ontwikkelen/produceren/uitgeven software</TableCell>
            <TableCell>Enschede</TableCell>
            <TableCell>Overijssel</TableCell>
            <TableCell>
              <Button onClick="" color="secondary">
                Voeg toe
              </Button>
            </TableCell>
            <TableCell>
              <Button onClick="" color="secondary">
                Maak lead
              </Button>
            </TableCell>
            <TableCell>75%%</TableCell>
          </TableRow>
          {companies.slice(0, 15).map(company => {
            console.log(company);
            return (
              <TableRow key={company.id}>
                <TableCell
                  onClick={() => {
                    handleClickOpen(company.id);
                  }}
                  className={classes.companyLink}
                >
                  {company['name'] ? company['name'] : '-'}
                </TableCell>
                <TableCell>
                  {company['orgtype'] ? company['orgtype'] : '-'}
                </TableCell>
                {/* <TableCell>{company['url'] ? company['url'][0] : '-'} </TableCell> */}
                <TableCell>
                  {company['categoryshort'] ? company['categoryshort'] : '-'}
                </TableCell>
                <TableCell>{company['city'] ? company['city'] : '-'}</TableCell>
                <TableCell>
                  {company['provincie'] ? company['provincie'] : '-'}
                </TableCell>
                <TableCell>
                  <Button onClick="" color="secondary">
                    Voeg toe
                  </Button>
                </TableCell>
                <TableCell>
                  <Button onClick="" color="secondary">
                    Maak lead
                  </Button>
                </TableCell>
                <TableCell>
                  {
                    leadscoresDemo[
                      company.coc.substring(company.coc.length - 1)
                    ]
                  }
                  %
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}

export default CompanyTable;
