import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Router } from '@reach/router';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import config from '../config/settings';
import * as types from '../constants/ActionTypes';
import App from '../containers/App';
import usePrefersDarkMode from '../hooks/usePrefersDarkMode';
import fetchResellerTheme from '../utilities/fetchResellerTheme';
import getSubdomain from '../utilities/getSubdomain';

function Root() {
  const dispatch = useDispatch();
  let prefersDarkMode = usePrefersDarkMode();

  const resellerTheme = useSelector(state => state.resellerTheme);

  useEffect(() => {
    let subdomain = getSubdomain();

    ReactGA.initialize(config.GOOGLE_ANALYTICS_UA_CODE, {
      debug: false,
      titleCase: false
    });
    ReactGA.plugin.require('ecommerce');
    ReactGA.pageview(window.location.pathname + window.location.search);

    async function fetchMyAPI() {
      dispatch({
        type: types.REQUEST_RESELLER_THEME
      });

      try {
        let result = await fetchResellerTheme(subdomain);
        dispatch({
          type: types.RECEIVE_RESELLER_THEME,
          resellerTheme: result
        });
      } catch (e) {
        dispatch({
          type: types.INVALIDATE_RESELLER_THEME,
          error: e
        });
      }
    }

    if (subdomain) {
      fetchMyAPI();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let {
    hasResellerTheme,
    primaryBrandColor,
    secondaryBrandColor
  } = resellerTheme;
  let subdomain = getSubdomain();

  let typeM = prefersDarkMode === true ? 'dark' : 'light';

  if (typeM === 'dark' && hasResellerTheme === false) {
    primaryBrandColor = config.BASE_THEME_DARK_PRIMARY_COLOR;
    secondaryBrandColor = config.BASE_THEME_DARK_SECONDARY_COLOR;
  }

  const themeMaterial = createMuiTheme({
    typography: {
      useNextVariants: true
    },
    palette: {
      type: typeM,
      primary: {
        main: '#23326b'
      },
      secondary: {
        main: '#ed1e36'
      },
      background: {
        default: '#ffffff'
      }
    }
  });

  if ((subdomain && hasResellerTheme === true) || subdomain === undefined) {
    return (
      <MuiThemeProvider theme={themeMaterial}>
        <Router>
          <App resellerTheme={resellerTheme} default />
        </Router>
      </MuiThemeProvider>
    );
  } else {
    return null;
  }
}

export default Root;
