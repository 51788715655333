import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
// import Hidden from '@material-ui/core/Hidden';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTheme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import CloseRounded from '@material-ui/icons/CloseRounded';
import FormatListNumberedOutlinedIcon from '@material-ui/icons/FormatListBulleted';
import SaveIcon from '@material-ui/icons/Save';
import SaveRounded from '@material-ui/icons/SaveAltRounded';
import SentimentDissatisfiedRounded from '@material-ui/icons/SentimentDissatisfiedRounded';
import ShareRounded from '@material-ui/icons/ShareRounded';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import { makeStyles } from '@material-ui/styles';
import { navigate } from '@reach/router';
import { Base64 } from 'js-base64';
import Cookies from 'js-cookie';
import md5 from 'md5';
import 'rc-tooltip/assets/bootstrap.css';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { FaGoogle, FaLinkedin } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import CompanyInfoDialog from '../components/CompanyInfoDialog';
import CompanyTable from '../components/CompanyTable';
import GdprDialog from '../components/GdprDialog';
import NavigationBar from '../components/NavigationBar';
import OnboardingScreen from '../components/OnboardingScreen';
import ShareLinkDialog from '../components/ShareLinkDialog';
import StylesAppContainer from '../components/StylesAppContainer';
import WebAppAutocomplete from '../components/WebAppAutocomplete';
import config from '../config/settings';
import * as types from '../constants/ActionTypes';
import * as dtypes from '../constants/DownloadTypes';
import CompanyFilterSettingsContainer from '../containers/CompanyFilterSettingsContainer';
import GeneralFilterSettingsContainer from '../containers/GeneralFilterSettingsContainer';
import LocationFilterSettingsContainer from '../containers/LocationFilterSettingsContainer';
import useDebounce from '../hooks/useDebounce';
import fetchCompanies2 from '../utilities/fetchCompanies';
import generateGrecaptchaCode from '../utilities/generateGrecaptchaCode';
import getParameterByName from '../utilities/getParameterByName';
import getSubdomain from '../utilities/getSubdomain';
import isBase64 from '../utilities/isBase64';
import shouldFetchCompanies from '../utilities/shouldFetchCompanies';

const drawerWidth = 360;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex'
  },

  speedDial: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  },

  tableWrapper: {
    overflowX: 'auto'
  },

  avatar: {
    background: theme.palette.primary.main
  },

  appBarWhite: {
    zIndex: theme.zIndex.drawer + 1,
    background: '#fff'
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    overflow: 'auto',
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },

  toolbar: theme.mixins.toolbar,

  margin: {
    margin: theme.spacing(1)
  },

  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  },
  bootstrapFormLabel: {
    fontSize: 18
  }
}));

const actions = [
  {
    icon: <SaveIcon />,
    name: 'Download standaard formaat',
    format: dtypes.STANDARD
  },
  {
    icon: <FaLinkedin size={'1.8em'} />,
    name: 'Download LinkedIn formaat',
    format: dtypes.LINKEDIN
  },

  {
    icon: <FaGoogle size={'1.8em'} />,
    name: 'Download Google Ads formaat',
    format: dtypes.ADWORDS
  },

  {
    icon: <ShareRounded />,
    name: 'Genereer deel link',
    format: dtypes.SHARE
  }
];

function App({ resellerTheme }) {
  const dispatch = useDispatch();

  const selectedQuery = useSelector(state => state.selectedQuery);
  const settings = useSelector(state => state.settings);
  const companiesByQuery = useSelector(state => state.companiesByQuery);

  const classes = useStyles();
  const theme = useTheme();

  const [companyDialogId, setCompanyDialogId] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [aboutDataOpen, setAboutDataOpen] = useState(false);
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [settingsBase64, setSettingsBase64] = useState('');
  const [open, setOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  console.log(selectedQuery);

  const debouncedSelectedQuery = useDebounce(selectedQuery, 500);

  // Md5 version of settings is used for redux bookkeeping
  const settingsMd5 = md5(JSON.stringify(settings));

  const {
    isFetching,
    items: companies,
    allowDownload,
    numFound
  } = companiesByQuery[selectedQuery + settingsMd5] || {
    isFetching: true,
    items: [],
    allowDownload: false,
    numFound: 0
  };

  // Convert Query params back to Redux Store value's
  useEffect(() => {
    let q = getParameterByName('q');
    let settingsBase64 = getParameterByName('settings');
    if (q) {
      dispatch({
        type: types.SELECT_QUERY,
        query: q
      });
    }
    if (settingsBase64) {
      if (isBase64(settingsBase64)) {
        let settingsString = Base64.decode(settingsBase64);
        let settings = JSON.parse(settingsString);
        dispatch({
          type: types.SET_SETTINGS,
          settings: settings
        });
      }
    }

    if (q || settingsBase64) {
      navigate(`/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get new companies when settings or query is changed
  useEffect(() => {
    async function fetchMyAPI() {
      dispatch({
        type: types.REQUEST_COMPANIES,
        query: debouncedSelectedQuery,
        settingsMd5
      });
      // Generate recaptcha code
      let grecaptchaCode = await generateGrecaptchaCode();
      let json = await fetchCompanies2(
        debouncedSelectedQuery,
        grecaptchaCode,
        settingsBase64 === ''
          ? Base64.encode(JSON.stringify(settings))
          : settingsBase64 //TODO: fix this
      );
      dispatch({
        type: types.RECEIVE_COMPANIES,
        query: debouncedSelectedQuery,
        settingsMd5,
        companies: json.companies,
        allowDownload: json.allowDownload,
        numFound: json.numFound,
        receivedAt: Date.now()
      });
    }
    if (shouldFetchCompanies(companiesByQuery, selectedQuery, settings)) {
      fetchMyAPI();
    }
    // Because companiesByQuery changed every time,
    // this effect will cause a loop when it is added to the dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsBase64, debouncedSelectedQuery]);

  useEffect(() => {
    setSettingsBase64(Base64.encode(JSON.stringify(settings)));
  }, [settings]);

  let { site_tech_apps } = settings;

  const isEmpty = companies ? companies.length === 0 : true;

  let tableHeaders = [];
  let tableColumnHeadersKeys = [];
  let companyDataForDialog = {};

  let shareLink = `${
    window.location.protocol
  }//${window.location.hostname.replace(
    'localhost',
    'localhost:3000'
  )}?q=${selectedQuery}&settings=${settingsBase64}`;

  if (companies) {
    for (let i = 0; i < companies.length; i++) {
      let company = companies[i];
      if (company.id === companyDialogId) {
        companyDataForDialog = company;
        break;
      }
    }

    if (companies.length > 0) {
      let firstCompany = companies[0];

      for (var prop in firstCompany) {
        // skip loop if the property is from prototype
        if (!firstCompany.hasOwnProperty(prop)) continue;

        tableHeaders.push(
          <TableCell style={{ textTransform: 'capitalize' }} key={prop}>
            {prop}
          </TableCell>
        );
        tableColumnHeadersKeys.push(prop);
      }
    }
  }

  let { palette } = theme;
  let { primary } = palette;

  const { hasResellerTheme } = resellerTheme;

  const drawer = (
    <>
      <div className={classes.toolbar} />
      <Divider />
      <GeneralFilterSettingsContainer />
      <Divider />
      <CompanyFilterSettingsContainer />
      <Divider />
      <LocationFilterSettingsContainer />
      <ListSubheader component="div">Website</ListSubheader>
      <WebAppAutocomplete site_tech_apps={site_tech_apps} />
      <div className="empty-spacing-div" style={{ margin: '200px 0 0 0' }} />
    </>
  );

  let onboardingActive2 =
    !(Cookies.get('already_onboard')
      ? Cookies.get('already_onboard') === 'true'
      : false) && isEmpty === false;

  function openDownloadLink(event, format) {
    if (format !== dtypes.SHARE) {
      let settingsBase64 = Base64.encode(JSON.stringify(settings));

      let addItemId = md5(selectedQuery ? selectedQuery : '*' + settingsBase64);

      ReactGA.plugin.execute('ecommerce', 'addItem', {
        id: addItemId,
        name: selectedQuery ? selectedQuery : '*',
        category: format,
        price: '1'
      });

      ReactGA.plugin.execute('ecommerce', 'addTransaction', {
        id: addItemId,
        affiliation: getSubdomain() ? getSubdomain() : 'default',
        revenue: '1'
      });

      let win = window.open(
        `${config.LEAD_GENERATOR_API_BASE_URL}/dev/download-data?q=${
          selectedQuery ? selectedQuery : '*'
        }&format=${format}&settings=${settingsBase64}`,
        '_blank'
      );
      win.focus();

      setOpen(!open);
    } else {
      ReactGA.modalview('/share-link');
      setOpenShareDialog(true);
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />

      <style>
        {`
          .grecaptcha-badge {
            display: none;
          }
          `}
      </style>
      <StylesAppContainer />
      <GdprDialog
        aboutDataOpen={aboutDataOpen}
        handleAboutDataClose={() => {
          setAboutDataOpen(false);
        }}
      />
      {/* <OnboardingScreen
        handleCloseOnBoarding={() => {
          Cookies.set('already_onboard', 'true');
        }}
        onboardingActive={onboardingActive2}
      /> */}
      <AppBar
        position="fixed"
        className={hasResellerTheme ? classes.appBarWhite : classes.appBar}
        color={
          hasResellerTheme
            ? 'inherit'
            : theme.palette.type === 'light'
            ? 'primary'
            : 'inherit'
        }
      >
        <NavigationBar
          resellerTheme={resellerTheme}
          handleDrawerToggle={() => {
            setMobileOpen(!mobileOpen);
          }}
        />
      </AppBar>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={() => {
          setMobileOpen(!mobileOpen);
        }}
        classes={{
          paper: classes.drawerPaper
        }}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
      >
        {drawer}
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={2}>
          {/* <Grid item xs={12}>
            <Card>
              <CardContent>
                {isFetching ? (
                  <div
                    className="layout flex horizontal center-center"
                    style={{ width: '100%' }}
                  >
                    <CircularProgress size={50} color="secondary" />
                  </div>
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <Typography
                      color="textSecondary"
                      variant="caption"
                      align="center"
                    >
                      Gevonden records
                    </Typography>
                    <Typography
                      color={
                        hasResellerTheme
                          ? 'primary'
                          : theme.palette.type === 'light'
                          ? 'primary'
                          : 'textPrimary'
                      }
                      variant="h6"
                      align="center"
                    >
                      {numFound.toLocaleString('nl', {
                        maximumFractionDigits: 0
                      })}{' '}
                      records
                    </Typography>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid> */}

          <Grid item xs={12}>
            <Card
              style={{
                borderTop: `6px solid ${primary.main}`,
                width: '100%',
                overflow: 'auto'
              }}
            >
              <CardHeader
                avatar={
                  <Avatar className={classes.avatar} aria-label="Google">
                    <FormatListNumberedOutlinedIcon />
                  </Avatar>
                }
                title="Selectie gevonden bedrijven"
                subheader="Bedrijven gevonden op basis van uw zoekcriteria"
              />

              <CardContent>
                {isEmpty ? (
                  isFetching ? (
                    <div
                      className="layout flex horizontal center-center"
                      style={{ width: '100%' }}
                    >
                      <CircularProgress size={50} color="secondary" />
                    </div>
                  ) : (
                    <div
                      className="layout flex horizontal center-center"
                      style={{ width: '100%' }}
                    >
                      <div className="layout flex vertical center-center ">
                        <SentimentDissatisfiedRounded />
                        <Typography variant="h6" align="center">
                          Geen resultaten gevonden
                        </Typography>
                      </div>
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      width: '100%',
                      overflow: 'auto',
                      opacity: isFetching ? 0.5 : 1,
                      postion: 'relative'
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        overflow: 'auto'
                      }}
                      className={classes.tableWrapper}
                    >
                      <CompanyTable
                        companies={companies}
                        handleClickOpen={companyId => {
                          ReactGA.modalview(`/company-modal/${companyId}`);
                          setOpenDialog(true);
                          setCompanyDialogId(companyId);
                        }}
                      />

                      <CompanyInfoDialog
                        companyDataForDialog={companyDataForDialog}
                        openDialog={openDialog}
                        handleClose={() => {
                          setOpenDialog(false);
                        }}
                        handleAboutDataClickOpen={() => {
                          ReactGA.modalview('/gdpr-zzp');
                          setAboutDataOpen(true);
                        }}
                      />
                    </div>
                  </div>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </main>
      <ShareLinkDialog
        shareLink={shareLink}
        openShareDialog={openShareDialog}
        handleShareClose={() => {
          setOpenShareDialog(false);
        }}
        onCopy={() => {}}
      />
      {/* {allowDownload === true ? (
        <SpeedDial
          style={{
            margin: 0,
            top: 'auto',
            right: 20,
            bottom: 20,
            left: 'auto',
            position: 'fixed',
            color: '#fff'
          }}
          ariaLabel="SpeedDial"
          className={classes.speedDial}
          hidden={isFetching}
          icon={
            <SpeedDialIcon
              icon={
                <span style={{ color: '#fff' }}>
                  <SaveRounded />
                </span>
              }
              openIcon={<CloseRounded />}
            />
          }
          onBlur={() => {
            setOpen(false);
          }}
          onClick={event => {
            setOpen(!open);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onFocus={() => {
            setOpen(true);
          }}
          transitionDuration={{ enter: 0, exit: 0 }}
          onMouseEnter={() => {
            setOpen(true);
          }}
          onMouseLeave={() => {
            setOpen(false);
          }}
          open={open}
        >
          {actions.map(action => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={event => {
                openDownloadLink(event, action.format);
              }}
            />
          ))}
        </SpeedDial>
      ) : (
        <Fab
          color="primary"
          style={{
            margin: 0,
            top: 'auto',
            right: 20,
            bottom: 20,
            left: 'auto',
            position: 'fixed',
            color: '#fff'
          }}
          onClick={event => {
            openDownloadLink(event, dtypes.SHARE);
          }}
        >
          <ShareRounded />
        </Fab>
      )} */}
    </div>
  );
}

export default App;
